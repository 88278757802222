import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@env/environment';
import { APP_VERSION } from '../../constants/constants.component';
import { Alert } from '../alert/alert';

export enum AlertLevels {
  ERROR,
  WARNING,
  INFO,
  SUCCESS,
  DEBUG,
}

export interface AlertData {
  level: number;
  message: string;
  code: string;
  translationKey?: string;
}

export const alertConfig: {
  [level: number]: {
    name: string;
    log: (msg: string) => void;
    autoDismissSeconds?: number;
  };
} = {
  [AlertLevels.ERROR]: {
    name: 'ERROR',
    log: (msg: string) => window.console && console.error(msg),
    autoDismissSeconds: null,
  },
  [AlertLevels.WARNING]: {
    name: 'WARNING',
    log: (msg: string) => window.console && console.warn(msg),
    autoDismissSeconds: null,
  },
  [AlertLevels.INFO]: {
    name: 'INFO',
    log: (msg: string) => window.console && console.info(msg), // tslint:disable-line
    autoDismissSeconds: null,
  },
  [AlertLevels.SUCCESS]: {
    name: 'SUCCESS',
    log: (msg: string) => window.console && console.log(msg),
    autoDismissSeconds: 10,
  },
  [AlertLevels.DEBUG]: {
    name: 'DEBUG',
    log: (msg: string) => window.console && console.log(msg),
    autoDismissSeconds: null,
  },
};

@Injectable({
  providedIn: 'root',
})
export class AlertService implements OnDestroy {
  _alerts: Map<number, Alert> = new Map();
  alertTimers: Map<number, any> = new Map();
  nextAlertId = 1;

  // clog = (msg, lvl) => { this.configService.logging.newMsg2( `${this.constructor.name}: ${msg}`, lvl); };

  get alerts(): Array<Alert> {
    return Array.from(this._alerts.values());
  }

  ngOnDestroy() {
    for (const alertTimer of this.alertTimers.values()) {
      clearInterval(alertTimer);
    }
  }

  createAlert(
    alertType: number,
    message: string,
    code: string,
    response?: HttpErrorResponse,
    translationKey?: string,
  ) {
    const alert = new Alert();
    alert.id = this.nextAlertId++;
    alert.type = alertType;
    alert.translationKey = translationKey;
    switch (alertType) {
      case AlertLevels.SUCCESS:
        alert.typeName = 'Success';
        alert.className = { 'alert-success': true };
        break;
      case AlertLevels.INFO:
        alert.typeName = 'Information';
        alert.className = { 'alert-info': true };
        break;
      case AlertLevels.WARNING:
        alert.typeName = 'Warning';
        alert.className = { 'alert-warning': true };
        break;
      case AlertLevels.ERROR:
        alert.typeName = 'Error';
        alert.className = { 'alert-danger': true };
        break;
      default:
        alert.typeName = '';
        alert.className = { 'alert-secondary': true };
    }
    if (message && code) {
      alert.message = message;
      alert.code = code;
      if (response) {
        alert.message =
          alert.message +
          ` (${response.statusText}: Status=${response.status})`;
      }
    } else if (response) {
      alert.code = (response.status || -1).toString();
      alert.message = response.statusText || 'Unknown Error';
    }
    alert.timestamp = Date.now();
    if (alert.type === AlertLevels.SUCCESS) {
      alert.secondsUntilDismissed = 10;
    }
    this._alerts.set(alert.id, alert);
    if (alertConfig[alertType].autoDismissSeconds) {
      alert.secondsUntilDismissed = alertConfig[alertType].autoDismissSeconds;
      this.alertTimers.set(
        alert.id,
        setInterval(() => {
          alert.secondsUntilDismissed--;
          if (alert.secondsUntilDismissed <= 0) {
            clearInterval(this.alertTimers.get(alert.id));
            this._alerts.delete(alert.id);
            this.alertTimers.delete(alert.id);
          }
        }, 1000),
      );
    }
    this.log(alert.type, alert.message);
  }

  dimsissAlert(alertId: number) {
    clearInterval(this.alertTimers.get(alertId));
    this._alerts.delete(alertId);
    this.alertTimers.delete(alertId);
  }

  createAlert2(ad: AlertData, err?: HttpErrorResponse) {
    this.createAlert(ad.level, ad.message, ad.code, err, ad.translationKey);
  }

  log(level: number, message: string) {
    if (window.console) {
      alertConfig[level].log(
        // `${new Date().toUTCString()}|${this.configService.appVersion}|${alertConfig[level].name}: ${message}`
        `${new Date().toUTCString()}|${APP_VERSION}-${environment.envName}|${
          alertConfig[level].name
        }: ${message}`,
      );
    }
  }
}
